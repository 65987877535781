import React, { useEffect, useState } from "react";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import { getQuickSightUrl } from "lib/api/admin";
import { LoadingSpinner } from "../../components/LoadingSpinner";

const EmbeddedDashboard = ({ url }) => {
  useEffect(() => {
    const embedDashboard = async () => {
      const embeddingContext = await createEmbeddingContext();
      await embeddingContext.embedDashboard({
        url: url,
        container: "#qs-dashboard-container",
        height: "100%",
        width: "100%",
      });
    };

    embedDashboard();
  }, [url]);

  return (
    <div
      id="qs-dashboard-container"
      style={{ height: "100%", width: "100%" }}
    ></div>
  );
};

const QuickSightPage = () => {
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardUrl = async () => {
      try {
        const response = await getQuickSightUrl();
        if (response?.success === "True") {
          setDashboardUrl(response?.data?.EmbedUrl);
        } else {
          console.error("Failed to fetch QuickSight URL:", response);
        }
      } catch (error) {
        console.error("Error fetching QuickSight URL:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardUrl();
  }, []);

  return (
    <div style={{ height: "100vh", width: "100vw", marginTop: "72px" }}>
      {loading ? (
        <LoadingSpinner middleFixed={true} />
      ) : (
        dashboardUrl && <EmbeddedDashboard url={dashboardUrl} />
      )}
    </div>
  );
};

export default QuickSightPage;
