// authenticatedAdminNavItems.js

import React from "react";
import { useSelector } from "react-redux";
import {
  NavigationConsumersIcon,
  NavigationAgentsIcon,
  NavigationProfileIcon,
  NavigationSettingsIcon,
} from "../icons/NavigationIcons";
import {
  SystemArchieveIcon16,
  // SystemCalendarIcon16,
} from "../icons/SystemIcons";

export const authenticatedAdminNavItems = () => {
  const featureFlags = useSelector(
    (state) => state.app.tenantSettings?.feature_flags || []
  );

  const quickSightEnabled = featureFlags.some(
    (flag) => flag.name === "quicksight" && flag.is_enabled
  );

  const navItems = [
    {
      title: "Customers",
      link: "/admin/consumers",
      iconURL: "#",
      icon: <NavigationConsumersIcon />,
    },
    {
      title: "Roles",
      link: "/admin/roles",
      iconURL: "#",
      icon: <NavigationAgentsIcon />,
    },
    {
      title: "Inventory",
      link: "/admin/inventory",
      iconURL: "#",
      icon: <SystemArchieveIcon16 />,
    },
    {
      title: "Tags",
      link: "/admin/settings",
      iconURL: "#",
      icon: <NavigationSettingsIcon />,
    },
      // {
  //   title: "Letters",
  //   link: "/admin/letters",
  //   iconURL: "#",
  //   icon: <SystemCalendarIcon16 />,
  // },
  // {
  //   title: "Disputes",
  //   link: "/admin/disputes",
  //   iconURL: "#",
  //   icon: <SystemCalendarIcon16 />,
  // },
    {
      title: "My Profile",
      link: "/admin/profile",
      iconURL: "#",
      icon: <NavigationProfileIcon />,
    },
  ];

  if (quickSightEnabled) {
    navItems.push({
      title: "QuickSight",
      link: "/admin/quicksight",
      iconURL: "#",
      icon: <NavigationProfileIcon />,
    });
  }

  return navItems;
};
